<template>
  <section class="network">
    <header class="hero-small">
      <div>
        <h1>
          Nous sommes une nouvelle génération<br />de constructeurs
        </h1>
      </div>
    </header>
    <main>
      <div class="grid-container">
        <section class="section-departements">
          <h2>Un réseau de 23 agences sur 17 départements</h2>
          <p id="carte">Prenant sa source en Charente-Maritime, le réseau d’agences Maisons Alysia se développe avec vivacité dans tout l’Ouest de la France, un secteur géographique de plus en plus prisé par son dynamisme et sa douceur de vivre. Le Grand Ouest fourmille de lieux où il fait bon s'installer et faire construire en satisfaisant les goûts et les modes de vie les plus divers et les plus exigeants.</p>
          <div class="card-departements">
            <h2>Sélectionnez un département <br/>pour trouver une agence :</h2>
            <div class="grid-x grid-margin-x grid-margin-y">
              <div class="cell medium-12 large-auto image-container">
                <img src="~@/assets/img/pages/network-map.png" alt="" />
              </div>
              <div class="agencies-link-container cell medium-12 large-auto">
                <div>
                  <router-link v-for="departement in openDepartements" :key="departement.number" :to="{ name: 'departement-agences', params: { slugDepartment: departement.linkName }}" class="departement-link open">
                    <div class="departement-number">{{ departement.number }}</div>
                    <div class="departement-name">{{ departement.name }}</div>
                    <div class="arrow"><arrow/></div>
                  </router-link>
                </div>
                <p v-if="closedDepartements.length">Ouverture en 2022 :</p>
                <div>
                  <div v-for="departement in closedDepartements" :key="departement.number" class="departement-link">
                    <div class="departement-number">{{ departement.number }}</div>
                    <div class="departement-name">{{ departement.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section-experience">
          <h2>Une expérience unique</h2>
          <p>Maisons Alysia est un concept qui revisite le rôle même de constructeur.  Circuit court, structure agile, des faits et pas des promesses. Vous allez adorer ce parcours de construction inédit.</p>
          <card-type-img title="Un modèle d'agence. Une agence modèle..." class="team-card">
            <template v-slot:content>
              <ul>
                <li>Une structure courte avec 2 personnes</li>
                <li>Un binôme tout entier dédié à l’écoute, au conseil et à l’accompagnement de chaque client</li>
                <li>Une équipe destinée à mener à bien chaque projet, du 1er contact à la remise des clés</li>
                <li>Une agence vouée à la qualité, limitée à la construction de 35 maisons par an</li>
                <li>Une garantie d’excellence pour le suivi de tous les chantiers</li>
              </ul>
            </template>
            <template v-slot:img>
              <img src="~@/assets/img/pages/illustration-manager-and-project-manager.png" alt="Un gérant qui est aussi le conducteur de travaux. Un(e) chargé(e) de projet qui assure la relation au quotidien."/>
            </template>
          </card-type-img>
          <card-type-img title="Chez Alysia, vous avez affaire au patron.">
            <template v-slot:content>
              <ul>
                <li>La mise au point et la finalisation de votre projet : c’est avec le gérant.</li>
                <li>A la signature du contrat, qui est là ? Le gérant.</li>
                <li>Qui est votre conducteur de travaux : c’est encore le gérant.</li>
                <li>Et qui effectue la livraison de votre maison : c’est toujours le gérant.</li>
              </ul>
              <p><b>Finalement, ce ne serait pas vous le boss ?</b></p>
            </template>
            <template v-slot:img>
              <img src="~@/assets/img/pages/man-with-cigar.png" alt="" class="center-top"/>
            </template>
          </card-type-img>
          <card-type-img title="Un showroom en agence. Pour tout choisir. Pour bien choisir." class="showroom-card">
            <template v-slot:content>
              <ul>
                <li>Disposer sur place de tout l’univers des produits de la marque.</li>
                <li>Finaliser ses choix de matériaux, accéder aux détails de finition. </li>
                <li>Choisir ses équipements, les revêtements, les carrelages et les faïences…</li>
              </ul>
              <p>Un délicieux privilège au bénéfice des futurs propriétaires.</p>
            </template>
            <template v-slot:img>
              <img src="~@/assets/img/pages/agence-showroom.png" alt=""/>
            </template>
          </card-type-img>
        </section>
      </div>
    </main>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

import arrow from '@/assets/img/arrow-right.svg?inline';

export default {
  components: {
    CardTypeImg,
    arrow,
  },
  data() {
    return {
      departements: [
        {
          number: 14,
          name: 'Calvados',
          linkName: 'calvados',
          open: true,
        },
        {
          number: 17,
          name: 'Charente-Maritime',
          linkName: 'charente-maritime',
          open: true,
        },
        {
          number: 22,
          name: 'Côtes-d\'Armor',
          linkName: 'cotes-darmor',
          open: true,
        },
        {
          number: 27,
          name: 'Eure',
          linkName: 'eure',
          open: true,
        },
        {
          number: 28,
          name: 'Eure-et-Loir',
          linkName: 'eure-et-loir',
          open: true,
        },
        {
          number: 29,
          name: 'Finistère',
          linkName: 'finistere',
          open: true,
        },
        {
          number: 33,
          name: 'Gironde',
          linkName: 'gironde',
          open: true,
        },
        {
          number: 35,
          name: 'Ille-et-Vilaine',
          linkName: 'ille-et-vilaine',
          open: true,
        },
        {
          number: 37,
          name: 'Indre-et-Loire',
          linkName: 'indre-et-loire',
          open: true,
        },
        {
          number: 44,
          name: 'Loire-Atlantique',
          linkName: 'loire-atlantique',
          open: true,
        },
        {
          number: 49,
          name: 'Maine-et-Loire',
          linkName: 'maine-et-loire',
          open: true,
        },
        {
          number: 56,
          name: 'Morbihan',
          linkName: 'morbihan',
          open: true,
        },
        {
          number: 72,
          name: 'Sarthe',
          linkName: 'sarthe',
          open: true,
        },
        {
          number: 76,
          name: 'Seine-Maritime',
          linkName: 'seine-maritime',
          open: true,
        },
        {
          number: 79,
          name: 'Deux-Sêvres',
          linkName: 'deux-sevres',
          open: true,
        },
        {
          number: 85,
          name: 'Vendée',
          linkName: 'vendee',
          open: true,
        },
        {
          number: 86,
          name: 'Vienne',
          linkName: 'vienne',
          open: true,
        },
      ],
    };
  },
  computed: {
    openDepartements() {
      return this.departements.filter((d) => d.open);
    },
    closedDepartements() {
      return this.departements.filter((d) => !d.open);
    },
  },
};
</script>

<style lang="sass">
section.network
  header.hero-small
    @include hero-small
    background: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/portraits.jpg) repeat
    background-size: contain
  main
    background-color: $medium-bg
    padding: 3rem 0
    section
      text-align: center
      color: $subtitle
      > h2
        margin-top: 2rem
      > p
        margin: 1.5rem auto 4rem auto
        max-width: 80%
        color: $subtitle
      .card-departements
        box-shadow: $drop-shadow
        background-color: $white
        padding: 1rem 3rem 3rem 3rem
        margin-bottom: 5rem
        h2
          margin-bottom: 2rem
        p
          padding: 1.2rem 0 1rem
          color: $black
          text-align: left
          margin-left: 0
        .departement-link
          display: flex
          justify-content: space-between
          border: 1px solid $line
          text-decoration: none
          font-size: 16px
          color: $black
          transition: background 0.3s ease
          .departement-number
            width: 2.5rem
            border-right: 1px solid $line
            background-color: $primary
            color: $white
          .departement-name
            width: 100%
            text-align: left
          div
            padding: 0.7rem
          svg
            path
              stroke: black
          &.open
            .departement-number
              background-color: #00BECB
            &:hover
              background: darken($white, 5%)
          &:not(:last-child)
            border-bottom: none
      .card-type-img
        text-align: left
        margin: 2rem 0
        color: $body-color
        b
          color: $colored-bg
        p
          margin-left: 0
          color: $body-color
        img.center-top
          object-position: center top
      .showroom-card
        img
          object-position: right top
  .image-container img
    width: 100%
  @media (max-width: 768px)
    .section-experience
      .team-card
        img
          object-fit: contain

</style>
